import React from 'react';
//import { makeStyles } from '@mui/styles';
import { ThemeProvider } from "@mui/material";
import { responsiveFontSizes, createTheme } from "@mui/material";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { styled } from '@mui/material/styles';
import CardContent from '@mui/material/CardContent';
import Client from './../components/Client';
import { ProSidebar, Menu as SidebarMenu, MenuItem as SidebarMenuItem, SubMenu, SidebarHeader, SidebarFooter, SidebarContent } from 'react-pro-sidebar';
import { FaHome, FaPizzaSlice, FaHamburger, FaMapMarkedAlt, FaEuroSign, FaUserTie, FaHospitalUser, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { Link } from 'react-router-dom';
import SettingsIcon from '@mui/icons-material/Settings';
import ChatIcon from '@mui/icons-material/Chat';
import SummarizeIcon from '@mui/icons-material/Summarize';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import EditLocationAltIcon from '@mui/icons-material/EditLocationAlt';
import EuroIcon from '@mui/icons-material/Euro';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import HomeIcon from '@mui/icons-material/Home';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import AnalyticsIcon from '@mui/icons-material/Analytics';

import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import ImageIcon from '@mui/icons-material/Image';

let theme = createTheme();


class SideBar extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      client: new Client ( "none" ),
      sideBar_collapsed: false,
    };

    this.toggleSideCollapsed = this.toggleSideCollapsed.bind(this);
    this.setActiveContent = this.setActiveContent.bind(this);
  }

  componentDidMount() {    
  }

  componentWillUnmount() {    
  }

  toggleSideCollapsed () {
    let collapsed = !this.state.sideBar_collapsed;
    this.setState({ sideBar_collapsed: !this.state.sideBar_collapsed });
    this.props.toggleSideCollapsed ( collapsed );
  }

  setActiveContent ( e ) {
    //this.setState({ mainContent : e })
    this.props.setActiveContent ( e );
  }


  render() { 
    return (
      <div className="sideBar">
          <ProSidebar collapsedWidth="70px" collapsed={this.state.sideBar_collapsed ? 1 : 0} sx={{ paddingTop: "0px", paddingBottom: "0px"}}>
          <SidebarMenu iconShape="square" sx={{ paddingTop: "0px", paddingBottom: "0px"}}>
            <SidebarMenuItem
              icon={this.state.sideBar_collapsed ? <FaChevronRight /> : <FaChevronLeft />}
              onClick={this.toggleSideCollapsed}
              collapsed={this.state.sideBar_collapsed ? 1 : 0}
              sx={{ paddingTop: "0px", paddingBottom: "0px"}}
            >
              <Link to="/" />
            </SidebarMenuItem>
            <SidebarMenuItem icon={<HomeIcon />}>
              Dashboard
              <Link to="#" onClick={() => this.setActiveContent("stats")} />
            </SidebarMenuItem>
            <SubMenu title="Products" icon={<FastfoodIcon />}>
              <SidebarMenuItem>
                Products
                <Link id="MenuItemHome" to="#" onClick={() => this.setActiveContent("products")} />
              </SidebarMenuItem>
              <SidebarMenuItem>
                Combos
                <Link id="MenuItemHome" to="#" onClick={() => this.setActiveContent("combos")} />
              </SidebarMenuItem>
              <SidebarMenuItem>
                Categories
                <Link id="MenuItemHome" to="#" onClick={() => this.setActiveContent("categories")} />
              </SidebarMenuItem>
              <SidebarMenuItem>
                Classes
                <Link id="MenuItemHome" to="#" onClick={() => this.setActiveContent("classes")} />
              </SidebarMenuItem>
            </SubMenu>
            <SubMenu title="Price Lists" icon={<EuroIcon />}>
              <SidebarMenuItem>
                Locations
                <Link id="MenuItemHome" to="#" onClick={() => this.setActiveContent("listsPrice")} />
              </SidebarMenuItem>
              <SidebarMenuItem>
                Promotions
                <Link id="MenuItemHome" to="#" onClick={() => this.setActiveContent("listsPromotion")} />
              </SidebarMenuItem>
              <SidebarMenuItem>
                Companies
                <Link id="MenuItemHome" to="#" onClick={() => this.setActiveContent("listsCompany")} />
              </SidebarMenuItem>
              <SidebarMenuItem>
                Products
                <Link id="MenuItemHome" to="#" onClick={() => this.setActiveContent("listsProduct")} />
              </SidebarMenuItem>
              <SidebarMenuItem>
                Combos
                <Link id="MenuItemHome" to="#" onClick={() => this.setActiveContent("listsCombo")} />
              </SidebarMenuItem>
              <SidebarMenuItem>
                Acquisitions
                <Link id="MenuItemHome" to="#" onClick={() => this.setActiveContent("listsAcquisition")} />
              </SidebarMenuItem>
            </SubMenu>
            <SubMenu title="Clients" icon={<ShoppingBagIcon />}>
              <SidebarMenuItem>
                Accounts
                <Link id="MenuItemHome" to="#" onClick={() => this.setActiveContent("clientsAccounts")} />
              </SidebarMenuItem>
              <SidebarMenuItem>
                Families
                <Link id="MenuItemHome" to="#" onClick={() => this.setActiveContent("clientsFamilies")} />
              </SidebarMenuItem>
              <SidebarMenuItem>
                Professional
                <Link id="MenuItemHome" to="#" onClick={() => this.setActiveContent("clientsProfessionals")} />
              </SidebarMenuItem>              
            </SubMenu>
            <SubMenu title="Locations" icon={<EditLocationAltIcon />}>
              <SidebarMenuItem>
                Locations
                <Link id="MenuItemHome" to="#" onClick={() => this.setActiveContent("locations")} />
              </SidebarMenuItem>
              <SidebarMenuItem>
                Groups
                <Link id="MenuItemHome" to="#" onClick={() => this.setActiveContent("locations_groups")} />
              </SidebarMenuItem>
              <SidebarMenuItem>
                Employees
                <Link id="MenuItemHome" to="#" onClick={() => this.setActiveContent("employees")} />
              </SidebarMenuItem>
            </SubMenu>
            <SubMenu title="Users" icon={<PeopleAltIcon />}>
              <SidebarMenuItem>
                Internal
                <Link id="MenuItemHome" to="#" onClick={() => this.setActiveContent("usersInternal")} />
              </SidebarMenuItem>
              <SidebarMenuItem>
                Clients
                <Link id="MenuItemHome" to="#" onClick={() => this.setActiveContent("usersClients")} />
              </SidebarMenuItem>
              {/* <SidebarMenuItem>
                External
              </SidebarMenuItem> */}
            </SubMenu>
            <SubMenu title="Registry" icon={<AssignmentIndIcon />}>
              <SidebarMenuItem>
                Persons
                <Link id="MenuItemHome" to="#" onClick={() => this.setActiveContent("addressBook_persons")} />
              </SidebarMenuItem>
              <SidebarMenuItem>
                Companies
                <Link id="MenuItemHome" to="#" onClick={() => this.setActiveContent("addressBook_companies")} />
              </SidebarMenuItem>
              {/* <SidebarMenuItem>
                External
                <Link id="MenuItemHome" to="#" onClick={() => this.setActiveContent("addressBook_external")} />
              </SidebarMenuItem> */}
            </SubMenu>

            <SubMenu title="Config" icon={<SettingsIcon />}>
              <SidebarMenuItem>
                Platform
                <Link id="MenuItemHome" to="#" onClick={() => this.setActiveContent("config_platform")} />
              </SidebarMenuItem>
              <SidebarMenuItem>
                Identity
                <Link id="MenuItemHome" to="#" onClick={() => this.setActiveContent("config_identity")} />
              </SidebarMenuItem>              
              <SidebarMenuItem>
                SalesApp
                <Link id="MenuItemHome" to="#" onClick={() => this.setActiveContent("config_salesapp")} />
              </SidebarMenuItem>
              <SidebarMenuItem>
                WebSite
                <Link id="MenuItemHome" to="#" onClick={() => this.setActiveContent("config_website")} />
              </SidebarMenuItem>
              <SidebarMenuItem>
                MobileApp
                <Link id="MenuItemHome" to="#" onClick={() => this.setActiveContent("config_mobileapp")} />
              </SidebarMenuItem>
            </SubMenu>

            <SubMenu title="Messages" icon={<ChatIcon />}>
              <SidebarMenuItem>
                Internal
                <Link id="MenuItemHome" to="#" onClick={() => this.setActiveContent("config_platform")} />
              </SidebarMenuItem>
              <SidebarMenuItem>
                Customers
                <Link id="MenuItemHome" to="#" onClick={() => this.setActiveContent("config_salesapp")} />
              </SidebarMenuItem>              
            </SubMenu>

            <SubMenu title="Logs" icon={<SummarizeIcon />}>
              <SidebarMenuItem>
                Internal
                <Link id="MenuItemHome" to="#" onClick={() => this.setActiveContent("config_platform")} />
              </SidebarMenuItem>
              <SidebarMenuItem>
                Customers
                <Link id="MenuItemHome" to="#" onClick={() => this.setActiveContent("config_salesapp")} />
              </SidebarMenuItem>              
            </SubMenu>

            <SubMenu title="BI Report" icon={<AnalyticsIcon />}>
            <SidebarMenuItem>
                Sales
                <Link id="MenuItemHome" to="#" onClick={() => this.setActiveContent("rap_sales")} />
              </SidebarMenuItem> 
              <SidebarMenuItem>
                Sales2
                <Link id="MenuItemHome" to="#" onClick={() => this.setActiveContent("rap_sales2")} />
              </SidebarMenuItem> 
              <SidebarMenuItem>
                Products
                <Link id="MenuItemHome" to="#" onClick={() => this.setActiveContent("rap_products")} />
              </SidebarMenuItem>
              <SidebarMenuItem>
                Products2
                <Link id="MenuItemHome" to="#" onClick={() => this.setActiveContent("rap_products2")} />
              </SidebarMenuItem>                                        
              <SidebarMenuItem>
                Clients
                <Link id="MenuItemHome" to="#" onClick={() => this.setActiveContent("rap_clients")} />
              </SidebarMenuItem>
         
            </SubMenu>

          </SidebarMenu>
        </ProSidebar>;
        </div> 
    );

  }
}

export default SideBar;